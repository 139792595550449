import { my, messager, candidate, recommend as recommend_new } from '#/js/config/api.json';
import tool from "#/js/tool";

export default {
    /**
     * 根据时间段获取各类工作台统计面板信息
     * @returns {*}
     */
    getStatistics : () => {
        return _request({
            method: 'POST',
            url: my.url_my_statistics,
        })
    },
    getTodoListStatistic () {
        return _request({
            method: 'GET',
            url: my.todo_list_statistic,
        })
    },
    /**
     * 候选人模块获取列表接口
     * @param Type 类型， 0为待沟通的，1为推荐的，2为收到的，3为代运营的, 缺省值为 1
     * @param Keywords 搜索关键字 缺省值为空
     * @param Status 搜索标签Tag对应的code 缺省值为空
     * @param JobId 不明，待补充 缺省值为空
     * @param StartDate 开始时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param EndDate 结束时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param start 翻页开始index 默认为0
     * @param take 单页数据 默认为10
     * @param scope 作用域
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getCandidateList: ({ Type = 1, Keywords = '', Status = '', JobId = '', StartDate = '', EndDate = '', start = 0, take = 10 } = {}, scope) => {
        return _request({
            method: 'GET',
            url: my.candidate_list,
            params: {
                Type: Type,
                Keywords: Keywords,
                Status: Status,
                JobId: JobId,
                StartDate: StartDate,
                EndDate: EndDate,
                start: start,
                take: take,
                '_': Number(new Date())
            }
        })
    },
    /**
     * 获取 推荐/offer 详情
     * @param start 
     * @param take 
     * @param recommendType 0: 推荐简历 1：推荐简历拿到offer
     * @param isMyCandidate true: 我的简历 false：他人简历
     * @returns {*}
     */
    getRecommendCandidates: ({ start, take, recommendType, isMyCandidate }) => {
        return _request({
            method: 'POST',
            url: my.recommend_candidates,
            data: {
                start : start || 0,
                take : take || 10,
                recommendType: recommendType || 0,
                isMyCandidate: isMyCandidate
            }
        })
    },
    /**
     * 获取收到的候选人时间轴事件接口
     * @param recommendationIds 需要获取的ID以逗号间隔 '[id],[id]....'
     * @param Type 类型， 0为待沟通的，1为推荐的，2为收到的，3为代运营的, 缺省值为 1
     * @param Keywords 搜索关键字 缺省值为空
     * @param Status 搜索标签Tag对应的code 缺省值为空
     * @param JobId 不明，待补充 缺省值为空
     * @param StartDate 开始时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param EndDate 结束时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param start 翻页开始index 默认为0
     * @param take 单页数据 默认为10
     * @param scope 作用域
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getTimeLine ({ recommendationIds = '', Type = 1, Keywords = '', Status = '', JobId = '', StartDate = '', EndDate = '', start = 0, take = 10 } = {}, scope) {
        return _request({
            method: 'GET',
            url: my.candidate_recommendation_timelines,
            params: {
                recommendationIds: recommendationIds,
                Type: Type,
                Keywords: Keywords,
                Status: Status,
                JobId: JobId,
                StartDate: StartDate,
                EndDate: EndDate,
                start: start,
                take: take,
                '_': Number(new Date())
            },
        })
    },

    /**
     * 获取推荐流程详情
     * @param recommendId 推荐记录id
     */
    getRecommendProcess(recommendId, params) {
        return _request({
            url: recommend_new.get_recommend_process.replace('%p', recommendId),
            method: 'POST',
            baseURL: "LbdOpenApi",
            data: params,
        })
    },


    /**
     * 进入面试/offer/入职/离职接口
     * @param RecommendationId 候选人id
     * @param Status 状态，进入面试 4, offer 8, 入职 16, 离职32,
     * @param ExtensionContent 暂不明,
     * @param OnboardDate 入职时间，
     * @param GuaranteeDays 保证期
     * @param OnekeyOfferModel offer相关字段
     *        @param OnekeyOfferModel.MonthPay
     *        @param OnekeyOfferModel.Months
     *        @param OnekeyOfferModel.commissionRate
     *        @param OnekeyOfferModel.RecruiteTime
     *        @param OnekeyOfferModel.Remark
     * @param __RequestVerificationToken
     */
    entry (params = {}){
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: recommend_new.recommend_candidate_stage,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        });
    },
    /**
     * 淘汰候选人接口
     * @param recommendationId 推荐ID
     * @param Reason 淘汰原因
     * @param ReasonDetail 淘汰原因明细
     */
    obsolete (params = { Reason, ReasonDetail, recommendationId, __RequestVerificationToken} = {}){
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: recommend_new.recommend_obsolete,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    /**
     *获取淘汰前状态接口
     * @param recommendationId 推荐ID
     */
    getLastStatus (recommendationId){
        return _request({
            method: 'GET',
            url: recommend_new.recommend_get_last_status.replace(/\%p/ig, recommendationId),
            throwBusinessError: true
        })
    },
    /**
     * 恢复候选人接口
     * @param recommendationId 推荐ID
     */
    recover (params = { recommendationId, __RequestVerificationToken} = {}){
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: recommend_new.recommend_recover.replace(/\%p/ig, params.recommendationId),
            data: params,
            throwBusinessError: true
        })
    },
    /**
     * 面试结果反馈
     * @param InterviewType 面试类型
     * @param Result 面试结果
     * @param Evaluation 面试评估
     * @param SharedResult 分享给所有猎头
     * @param interviewId 
     */
    feedback (params = {InterviewType,Result,Evaluation,SharedResult, interviewId, __RequestVerificationToken} = {}){
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: recommend_new.recommend_interview_feedback,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    /**
     * 面试取消
     * @param recommendationId
     * @param interviewid
     * @param token
     * @returns {AxiosPromise}
     */
    cancel (recommendationId, interviewid, token) {
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: messager.cancel_candidate_interview.replace(/\%p/ig,interviewid),
            data: {
                recommendationId: recommendationId,
                interviewId:interviewid,
                __RequestVerificationToken : token
            },
            transformRequest: [(data) => {
                return tool.toFormData(data)
            }]
        })
    },
    /**
     * 取消面试
     * @param interviewId
     * @param unionId
     */
    cancelInterview (params) {
        return _request({
            method: 'POST',
            url: recommend_new.interview_cancel,
            baseURL: "LbdOpenApi",
            data: params,
        })
    },
    /**
    * 筛选通过
    * @param recommendationId
    * @param interviewid
    * @param token
    * @returns {AxiosPromise}
    */
    approve (recommendationId,token){
        return _request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: messager.approve_candidate.replace(/\%p/ig,recommendationId),
            data: {
                recommendationId: recommendationId,
                __RequestVerificationToken : token,
                acceptable: true
            },
            transformRequest: [(data) => {
                return tool.toFormData(data)
            }]
        })
    },
    /**
     * 安排面试
     * @param id 候选人id
     * @param type 面试类型 0为现场面试，1为电话面试
     * @param time 面试时间 format: YYYY-MM-DD hh:mm
     * @param address 面试地址 最少6位长度
     * @param remark 留言，最多50字
     * @param {boolean} shouldSendNotification 是否需要使用短信邮件通知对方
     */
    interview(params = {recommendationId, interviewType, InterviewTime, InterviewAddress, Remark, shouldSendNotification, __RequestVerificationToken} = {}) {
        return _request({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: recommend_new.recommend_arrange_interview,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                if(err.message) {
                    shortTips(err.message);
                } else {
                    let data = err.data, errMsg = [];
                    Object.keys(data).forEach(item => {
                        if(data[item].length > 0) {
                            for(let i = 0; i < data[item].length; i++) {
                                errMsg.push(data[item][i]);
                            }
                        }
                    });
                    if(errMsg.length > 0) {
                        shortTips(errMsg.join('<br/>'));
                    } else {
                        shortTips("系统异常，请稍后重试");
                    }
                }
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    /**
     * 获取所有的job列表
     * Yapi地址 http://192.168.1.72:3000/project/20/interface/api/696
     * @param type 7-待沟通，8-推荐的，9-收到的
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    allJob(type){
        return _request({
            method: 'GET',
            url: my.all_job,
            data: {
                type: type
            }
        })
    },
    /**
     * 是否接受候选人
     * @param recommendationId 推荐id
     * @param {boolean} Acceptable 是否接受，
     * @param rejectDetail 淘汰备注
     * @param {Array} rejectReason 拒绝原因
     */
    accept (params = { recommendationId, Acceptable, rejectDetail, rejectReason, __RequestVerificationToken} = {}){
        return _request({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: recommend_new.recommend_accept_or_reject,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    /**
     * 一键OFFER
     * @param RecommendationId 候选人ID
     * @param MonthPay 月薪
     * @param Months 年薪多少个月
     * @param commissionRate 佣金比例
     * @param RecruiteTime 入职时间
     * @param Remark 备注
     * @param __RequestVerificationToken
     */
    offer ( params = {} ){
        return _request({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: recommend_new.recommend_onekey_offer,
            data: params,
            throwBusinessError: true
        }).then(res => {
            return Promise.resolve(res);
        },err => {
            if(!err.succeeded){
                shortTips(err.message);
            } else {
                return Promise.reject(err.data);
            }
        })
    },
    /**
     * 获取推荐的候选人时间轴事件接口
     * @param recommendationIds 需要获取的ID以逗号间隔 '[id],[id]....'
     * @param Type 类型， 0为待沟通的，1为推荐的，2为收到的，3为代运营的, 缺省值为 1
     * @param Keywords 搜索关键字 缺省值为空
     * @param Status 搜索标签Tag对应的code 缺省值为空
     * @param JobId 不明，待补充 缺省值为空
     * @param StartDate 开始时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param EndDate 结束时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param start 翻页开始index 默认为0
     * @param take 单页数据 默认为10
     * @param scope 作用域
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getRecommendations ({candidateIds = '',Type = 1,Keywords = '',Status = '',JobId = '',StartDate = '',EndDate = '',start = 0,take = 10} = {}, scope){
        return _request({
            method: 'GET',
            url: my.candidate_recommendation_lists,
            data: {
                candidateIds: candidateIds,
                Type: Type,
                Keywords: Keywords,
                Status: Status,
                JobId: JobId,
                StartDate: StartDate,
                EndDate: EndDate,
                start: start,
                take: take,
                '_': Number(new Date())
            }
        })
    },
    /**
     * 获取待沟通的候选人时间轴事件接口
     * @param recommendationIds {Array} 需要获取的ID
     * @param Type {number} 类型， 0为待沟通的，1为推荐的，2为收到的，3为代运营的, 缺省值为 1
     * @param Keywords 搜索关键字 缺省值为空
     * @param Status 搜索标签Tag对应的code 缺省值为空
     * @param JobId 不明，待补充 缺省值为空
     * @param StartDate 开始时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param EndDate 结束时间 缺省值为当前日期 format(YYYY/M/D hh:mm:ss)
     * @param start 翻页开始index 默认为0
     * @param take 单页数据 默认为10
     * @param scope 作用域
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getMyCommunicationJobs ({candidateIds = '',Type = 1,Keywords = '',Status = '',JobId = '',StartDate = '',EndDate = '',start = 0,take = 10} = {}, scope){
        return _request({
            method: 'POST',
            url: my.communication_jobs,
            data: {
                candidateIds: candidateIds,
                Type: Type,
                Keywords: Keywords,
                Status: Status,
                JobId: JobId,
                StartDate: StartDate,
                EndDate: EndDate,
                start: start,
                take: take,
                '_': Number(new Date())
            },
            transformRequest: [(data) => {
                return tool.toFormData(data)
            }],
        })
    },
    /**
     * 删除沟通记录
     * @param id 候选人ID
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    deleteMycommunication (id){
        return _request({
            method: 'GET',
            url: candidate.url_delete_communication.replace(/\%p/ig,id),
            data: {
                '_': Number(new Date())
            },
        })
    },
    /**
     * 获取 查看简历 详情
     * @param start 
     * @param take 
     * @param candidateViewType 0: 浏览简历 1：查看简历电话 2：查看简历邮箱
     * @param isMyCandidate true: 我的简历 false：他人简历
     * @returns {*}
     */
    getViewCandidates : ({ start, take, candidateViewType, isMyCandidate }) => {
        return _request({
            method: 'POST',
            url: my.view_candidates,
            data: {
                start : start || 0,
                take : take || 10,
                candidateViewType: candidateViewType || 0,
                isMyCandidate: isMyCandidate
            }
        })
    },
    /**
     * 获取业绩提成
     * @param recommendId 推荐id
     * @returns {*}
     */
    getPerfomanceSplitInfo: ({ recommendId }) => {
        return _request({
            method: 'GET',
            url: recommend_new.get_performance_split_info.replace(/\%p/ig, recommendId)
        });
    },
    /**
     * 获取offer编辑数据 
     * @param recommendId 推荐id
     * @returns {*}
     */
    getEditOfferInfo: ({ recommendId }) => {
        return _request({
            method: 'GET',
            url: recommend_new.get_edit_offer_info.replace(/\%p/ig, recommendId),
            baseURL: "LbdOpenApi",
        });
    },
    /**
     * 更新offer数据 
     * @param recommendId 推荐id
     * @returns {*}
     */
    updateOfferInfo: (recommendId, params = {}) => {
        return _request({
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: recommend_new.update_offer_info.replace(/\%p/ig, recommendId),
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    /**
     * 更新offer数据-不同公司的情况下-推荐者-编辑业绩弹窗
     * @param recommendId 推荐id
     * @returns {*}
     */
    updateOfferByRecommender: (recommendId, params = {}) => {
        return _request({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            url: recommend_new.update_offer_by_recommender.replace(/\%p/ig, recommendId),
            data: params
        });
    },
    /**
     * 获取offer编辑日志
     * @param recommendId 推荐id
     * @returns {*}
     */
    getOfferEditLog: (recommendId) => {
        return _request({
            method: 'GET',
            url: recommend_new.get_offer_edit_log.replace(/\%p/ig, recommendId)
        });
    },

    /**
     * 更新offer状态
     * @param params 
     * @returns {*}
     */
     updateOfferingStage: (params = {}) => {
        return _request({
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // },
            url: recommend_new.recommend_offering_stage,
            data: params
        });
    },
    /**
     * HR职位，猎头端补充编辑业绩弹窗
     * @param recommendId 推荐id
     * @returns {*}
     */
    replenishOfferByRecommender: (recommendId, params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.replenish_offer_by_recommender.replace(/\%p/ig, recommendId),
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    /**
     * 是否接受候选人
     * @param recommendationId 推荐id
     * @param {boolean} Acceptable 是否接受，
     * @param rejectDetail 淘汰备注
     * @param {Array} rejectReason 拒绝原因
     */
     acceptOrRreject: (params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.accept_or_reject,
            baseURL: "LbdOpenApi",
            data: params
        });
        // .then(res => {
        //     return Promise.resolve(res);
        // },err => {
        //     if(!err.succeeded || !err.success){
        //         shortTips(err.message || err.error?.description);
        //     } else {
        //         return Promise.reject(err.data);
        //     }
        // })
    },

    /**
     *  推荐流程操作：进入面试/offer/入职/离职
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendStage: ( params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.stage,
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    /**
     *  推荐流程操作：安排面试
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendInterview: ( params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.interview,
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    /**
     *  推荐流程操作：反馈面试结果
     * @param recommendId 推荐id
     * @returns {*}
     */
    interviewFeedback: ( params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.interviewFeedback,
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    /**
     *  推荐流程操作：淘汰
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendObsolete: ( params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.obsolete,
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    /**
     *  推荐流程操作：回复
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendRecover: ( recommendId, params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.recover.replace(/\%p/ig, recommendId),
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    /**
     *  推荐流程操作：offer子状态
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendOfferingStage: (params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.offeringStage,
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    /**
     *  进入Offering
     */
    recommendEntryOffering: (params = {}) => {
        return _request({
            method: 'POST',
            url: recommend_new.entryOffering,
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    /**
     *  获取推荐面试列表
     * @param recommendId 推荐id
     * @returns {*}
     */
    recommendationInterviews: ( recommendId ) => {
        return _request({
            method: 'GET',
            url: recommend_new.recommendation_interviews.replace('%p', recommendId),
            baseURL: "LbdOpenApi",
        });
    },

    /**
     * 确认进入面试
     * @param {*} params 
     * @returns 
     */
    toConfirmInterview(params) {
        return _request({
          url: recommend_new.toConfirmInterview,
          method: "POST",
          baseURL: "LbdOpenApi",
          data: params,
        });
    },

    /**
     * 获取安排面试数据
     * @param {string} recommendId 
     * @returns {*}
     */
    arrangeInterviewsInfo(recommendId) {
        return _request({
          url: recommend_new.arrangeInterviewsInfo.replace('%p', recommendId),
          method: "GET",
          baseURL: "LbdOpenApi",
        });
    },
    
}
